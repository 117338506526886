<template>
  <DoughnutChart :chartData="chartData" :options="chartOptions" />
</template>

<script>
import { DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  name: 'FundsChart',
  components: { DoughnutChart },
  props: ['data'],
  computed: {
    chartData() {
      return {
        datasets: [
          {
            data: this.data,
            backgroundColor: [
              this.$vuetify.theme.themes.theme.colors['amethyst-lighten-2'],
              this.$vuetify.theme.themes.theme.colors['amethyst-lighten-1'],
              this.$vuetify.theme.themes.theme.colors.amethyst,
            ],
          },
        ],
        labels: [
          this.$t('closingBalance.banknotes'),
          this.$t('closingBalance.cards'),
          this.$t('closingBalance.other'),
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      };
    },
  },
};
</script>
