import { createI18n } from 'vue-i18n';
import store from '../store';
import messages from '../locales/messages';

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: store.getLanguage() || 'hr',
  fallbackLocale: 'hr',
  messages,
});
