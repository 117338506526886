import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { auth } from './plugins/firebase';
import router from './router';
import i18n from './plugins/i18n';
import VueNumber from 'vue-number-animation';
import './registerServiceWorker';

loadFonts();

let app;

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App).use(vuetify).use(i18n).use(router).use(VueNumber).mount('#app');
  }
});
