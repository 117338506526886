<template>
  <v-list nav :disabled="disableNavMenu">
    <v-list-item
      prepend-icon="mdi-home"
      :title="$t('home')"
      value="dashboard"
      active-color="primary"
      :to="{ name: 'dashboard' }"
    ></v-list-item>

    <v-list-group>
      <template v-slot:activator="{ props }">
        <v-list-item
          v-bind="props"
          :title="$t('warehouse.title')"
          prepend-icon="mdi-warehouse"
        ></v-list-item>
      </template>
      <v-list-item
        v-for="(item, i) in warehouseItems"
        :key="i"
        :title="item.text"
        :value="item.value"
        :to="item.to"
        active-color="primary"
      ></v-list-item>
    </v-list-group>

    <v-list-item
      prepend-icon="mdi-finance"
      :title="$t('financeReports')"
      value="reports"
      active-color="primary"
      :to="{ name: 'reports' }"
    ></v-list-item>

    <v-list-item
      prepend-icon="mdi-cash-register"
      :title="$t('closingBalance.title')"
      value="closingBalance"
      active-color="primary"
      :to="{ name: 'closingBalance' }"
    ></v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'AppSidebar',
  props: ['disableNavMenu'],
  computed: {
    warehouseItems() {
      return [{ text: this.$t('warehouse.stock'), value: 'warehouse', to: { name: 'warehouse' } }];
    },
  },
};
</script>
