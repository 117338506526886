// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';

const theme = {
  dark: false,
  colors: {
    background: '#F9F9F9',
    surface: '#FFFFFF',
    primary: '#2597C5',
    secondary: '#E65100',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    incoming: '#36A2EB',
    outgoing: '#FF6384',
    amethyst: '#7E57C2',
    'amethyst-lighten-1': '#B39DDB',
    'amethyst-lighten-2': '#D1C4E9',
  },
};

export default createVuetify({
  theme: {
    defaultTheme: 'theme',
    variations: {
      colors: ['primary', 'error', 'info', 'success', 'warning'],
      lighten: 4,
      darken: 1,
    },
    themes: {
      theme,
    },
  },
});
