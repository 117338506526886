const rules = {
  required(message) {
    return (val) => !!val || val === 0 || message;
  },
  email(message) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\u00C0-\u024F\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (val) => !val || !!val.match(regex) || message;
  },
};

export default rules;
