<template>
  <v-navigation-drawer v-model="drawer">
    <v-list>
      <v-list-item :title="fullName" :subtitle="currentCompany">
        <template v-slot:prepend>
          <v-avatar color="secondary" size="large">
            <span class="text-h5">{{ initials }}</span>
          </v-avatar>
        </template>
        <template v-slot:append>
          <v-btn
            variant="text"
            size="small"
            icon="mdi-close"
            @click.stop="drawer = !drawer"
          ></v-btn>
        </template>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <AppSidebar :disableNavMenu="disableNavMenu" />
    <template v-slot:append>
      <div class="pa-4">
        <v-btn block @click="logout" color="primary">{{ $t('logout') }}</v-btn>
      </div>
    </template>
  </v-navigation-drawer>

  <v-app-bar color="primary">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title class="text-uppercase">
      <span class="font-weight-bold">Za</span>
      <span class="font-weight-thin">gazde</span>
    </v-app-bar-title>

    <template v-slot:append>
      <LanguageSelect v-if="showLanguage" />
      <LocationSelect v-if="!disableNavMenu" />
      <CompanySelect v-if="!disableNavMenu" />
      <AppContextMenu />
    </template>
  </v-app-bar>

  <v-main>
    <slot></slot>
  </v-main>
</template>

<script>
import store from '../store';
import { endSession } from '../plugins/auth';
import AppSidebar from './AppSidebar.vue';
import LanguageSelect from './LanguageSelect.vue';
import AppContextMenu from './AppContextMenu.vue';
import CompanySelect from './CompanySelect.vue';
import { truncateString } from '../utils/util';
import LocationSelect from './LocationSelect.vue';

export default {
  name: 'AppLayout',
  data() {
    return {
      drawer: false,
    };
  },
  props: ['disableNavMenu'],
  computed: {
    fullName() {
      const user = store.getUser();
      return user.name + ' ' + user.surname;
    },
    initials() {
      const user = store.getUser();
      return user.name.substring(0, 1);
    },
    currentCompany() {
      return truncateString(store.getCurrentCompany().name, 25);
    },
    showLanguage() {
      return process.env.NODE_ENV !== 'production';
    },
  },
  methods: {
    logout() {
      endSession();
      this.$router.push({ name: 'login' });
      store.removeUserId();
    },
  },
  components: { AppSidebar, LanguageSelect, AppContextMenu, CompanySelect, LocationSelect },
};
</script>
