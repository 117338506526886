function calculateIncoming(deliveryNotes) {
  let totalPn = 0;
  let base = 0;
  let tax = 0;
  deliveryNotes.forEach((note) => {
    const extendedItems = Object.values(note.extended_items);
    extendedItems.forEach((item) => {
      const warehouseData = item.warehouse_data;
      const rabat = warehouseData.supplier_rebate / 10000;
      let totalPriceWithRabat =
        warehouseData.received_amount_purchase_price -
        warehouseData.received_amount_purchase_price * rabat;
      totalPriceWithRabat = isNaN(totalPriceWithRabat) ? 0 : totalPriceWithRabat;
      let totalPriceNoPdv = (totalPriceWithRabat * warehouseData.received_amount_quantity) / 1000;
      totalPriceNoPdv = isNaN(totalPriceNoPdv) ? 0 : totalPriceNoPdv;

      base += totalPriceNoPdv;
      tax += (totalPriceNoPdv * warehouseData.vat) / 10000;
      if (warehouseData.retail_price && warehouseData.retail_price > 0) {
        totalPn += warehouseData.retail_price * (item.item.stock / 1000);
      }
    });
  });
  const total = (base + tax + totalPn) / 1000;
  return { base, tax, total };
}

export { calculateIncoming };
