<template>
  <v-menu location="bottom" :close-on-content-click="true">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" icon="mdi-translate"></v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-subheader class="text-uppercase">{{ $t('language') }}</v-list-subheader>

        <v-list-item
          v-for="lang in languages"
          :key="lang.value"
          :value="lang.value"
          active-color="primary"
          :active="lang.value === currentLanguage"
          @click="changeLanguage(lang.value)"
          >{{ $t(lang.name) }}
          <template v-slot:prepend>
            <v-icon :icon="lang.value === currentLanguage ? 'mdi-check-bold' : ''"></v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import store from '../store';

export default {
  name: 'LanguageSelect',
  data() {
    return {
      languages: [
        { name: 'languages.croatian', value: 'hr' },
        { name: 'languages.english', value: 'en' },
      ],
    };
  },
  computed: {
    currentLanguage() {
      return store.getLanguage();
    },
  },
  methods: {
    changeLanguage(language) {
      store.setLanguage(language);
      this.$i18n.locale = language;
    },
  },
};
</script>
