<template>
  <AppLayout>
    <div class="progress-loader">
      <v-progress-linear v-if="loading" indeterminate color="grey"></v-progress-linear>
    </div>
    <v-overlay v-model="loading" class="align-center justify-center">
      <v-progress-circular
        indeterminate
        color="grey-darken-1"
        :size="80"
        :width="10"
      ></v-progress-circular>
    </v-overlay>
    <router-view></router-view>
  </AppLayout>
</template>

<script>
import AppLayout from '../components/AppLayout.vue';

export default {
  name: 'HomeView',
  components: { AppLayout },
  data() {
    return {
      loading: false,
    };
  },
  provide() {
    return {
      showLoader: () => {
        this.loading = true;
      },
      hideLoader: () => {
        this.loading = false;
      },
    };
  },
};
</script>

<style>
.progress-loader {
  height: 5px;
}
</style>
