<template>
  <v-menu v-if="showCompanySelect" location="bottom" :close-on-content-click="true">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" icon="mdi-factory"></v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-subheader class="text-uppercase">{{ $t('companies') }}</v-list-subheader>
        <v-list-item
          v-for="company in companies"
          :key="company.id"
          :value="company.name"
          :active="company.id === currentCompanyId"
          active-color="primary"
          @click="changeCompany(company)"
        >
          <template v-slot:prepend>
            <v-icon :icon="company.id === currentCompanyId ? 'mdi-check-bold' : ''"></v-icon>
          </template>
          {{ company.name }}
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { getWarehouses, getLocations } from '../queries';
import store from '../store';
import { truncateString } from '../utils/util';

export default {
  name: 'CompanySelect',
  inject: ['showLoader', 'hideLoader'],
  computed: {
    showCompanySelect() {
      return store.allCompanies.length > 1;
    },
    currentCompanyId() {
      return store.getCurrentCompanyId();
    },
    companies() {
      return store.allCompanies
        .map((company) => {
          return {
            id: company.id,
            name: company.name,
            shortName: truncateString(company.name, 25),
          };
        })
        .sort((company1, company2) => {
          return company1.name < company2.name ? -1 : 1;
        });
    },
  },
  mounted() {
    this.getCompanyData(this.currentCompanyId);
  },
  methods: {
    changeCompany(company) {
      store.setCurrentCompanyId(company.id);
      this.getCompanyData(company.id);
    },
    async getCompanyData(companyId) {
      this.showLoader();
      store.companyLocations = await getLocations(companyId);
      store.companyWarehouses = await getWarehouses(companyId);
      if (store.companyWarehouses.length > 0) {
        store.setCurrentWarehouse(store.companyWarehouses[0]);
      }
      this.hideLoader();
    },
  },
};
</script>
