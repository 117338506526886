<template>
  <div :style="chartContainerStyle">
    <BarChart :chartData="chartData" :options="chartOptions" :height="barHeight" />
  </div>
</template>

<script>
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { currencyFormat } from '../../utils/util';

Chart.register(...registerables);

export default {
  name: 'FinancialReportChart',
  components: { BarChart },
  props: ['providedData'],
  computed: {
    chartData() {
      return {
        datasets: [
          {
            maxBarThickness: 50,
            data: [...this.providedData.data1],
            backgroundColor: [this.$vuetify.theme.themes.theme.colors.outgoing + '60'],
            borderColor: [this.$vuetify.theme.themes.theme.colors.outgoing + '90'],
            borderWidth: 2,
          },
          {
            maxBarThickness: 50,
            data: [...this.providedData.data2],
            backgroundColor: [this.$vuetify.theme.themes.theme.colors.incoming + '60'],
            borderColor: [this.$vuetify.theme.themes.theme.colors.incoming + '90'],
            borderWidth: 2,
          },
        ],
        labels: this.providedData.labels,
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            grid: {
              display: true,
            },
            border: {
              display: true,
            },
            ticks: {
              callback: (value) => currencyFormat(value),
            },
          },
          y: {
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: (context) => {
                const data = context[0].dataset.data[context[0].dataIndex];
                context[0].formattedValue = currencyFormat(data);
              },
            },
          },
        },
      };
    },
    barHeight() {
      return this.$vuetify.display.smAndUp ? 300 : 250;
    },
    chartContainerStyle() {
      return {
        width: this.$vuetify.display.smAndUp ? '60%' : 'auto',
        margin: 'auto',
      };
    },
  },
};
</script>
