<template>
  <LineChart
    v-if="showChart"
    :chartData="chartData"
    :options="chartOptions"
    :height="chartHeight"
  />
  <div v-else class="text-grey text-center pt-10">{{ $t('charts.noData') }}</div>
</template>

<script>
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { currencyFormat } from '../../utils/util';

Chart.register(...registerables);

export default {
  name: 'dashboardLineChart',
  components: { LineChart },
  props: ['providedData', 'hideLegend'],
  computed: {
    chartData() {
      return {
        datasets: [
          {
            data: this.providedData.data,
            borderColor: [this.$vuetify.theme.themes.theme.colors.primary + '90'],
            backgroundColor: [this.$vuetify.theme.themes.theme.colors.primary + '30'],
            fill: true,
          },
        ],
        labels: this.providedData.labels,
      };
    },
    chartHeight() {
      return 250;
    },
    chartOptions() {
      return {
        responsive: true,
        tension: 0.2,
        maintainAspectRatio: false,
        elements: {
          point: {
            pointStyle: false,
          },
        },
        plugins: {
          legend: {
            display: !this.hideLegend,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              callback: (value) => currencyFormat(value),
            },
          },
        },
      };
    },
    showChart() {
      return this.chartData.labels.length > 1 && this.chartData.datasets[0].data.length > 1;
    },
  },
};
</script>
