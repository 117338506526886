function groupReceiptsBySellerName(receipts) {
  const receiptsMap = new Map();

  receipts.forEach((receipt) => {
    if (receiptsMap.has(receipt.seller_name)) {
      receiptsMap.get(receipt.seller_name).push(receipt);
    } else {
      receiptsMap.set(receipt.seller_name, [receipt]);
    }
  });
  return receiptsMap;
}

function groupReceiptsByItemName(receipts) {
  const receiptsMap = new Map();

  receipts.forEach((receipt) => {
    receipt.items.forEach((item) => {
      if (receiptsMap.has(item.name)) {
        receiptsMap.get(item.name).push(receipt);
      } else {
        receiptsMap.set(item.name, [receipt]);
      }
    });
  });

  return receiptsMap;
}

function groupReceiptsByIssuedHour(receipts) {
  const receiptsByHourMap = new Map();

  receipts.forEach((receipt) => {
    const issueDateTime = new Date(1000 * receipt.issue_date_time);
    const issueDateHour = issueDateTime.getHours();
    if (receiptsByHourMap.has(issueDateHour)) {
      receiptsByHourMap.get(issueDateHour).push(receipt);
    } else {
      receiptsByHourMap.set(issueDateHour, [receipt]);
    }
  });

  const sortedMap = new Map(
    [...receiptsByHourMap.entries()].sort((a, b) => {
      return Number(a[0]) < Number(b[0]) ? -1 : 1;
    }),
  );

  return sortedMap;
}

function filterReceiptsByTimestamp(receipts, mode, timestamp) {
  if (mode === 'after') {
    return receipts.filter((receipt) => receipt.issue_date_time >= timestamp);
  }
  if (mode === 'before') {
    return receipts.filter((receipt) => receipt.issue_date_time <= timestamp);
  }
}

function filterReceiptsBySellerNames(receipts, sellerNames) {
  return receipts.filter((receipt) => sellerNames.includes(receipt.seller_name));
}

function filterReceiptsByLocationId(receipts, locationId) {
  return receipts.filter((receipt) => receipt.designation.location_id === locationId);
}

function getTotal(receipts) {
  return (receipts.reduce((sum, receipt) => sum + receipt.total, 0) / 100).toFixed(2);
}

function getTotalTax(receipts) {
  receipts = receipts.filter((receipt) => {
    return receipt.pdv;
  });

  return (receipts.reduce((sum, receipt) => sum + receipt.pdv[0].total, 0) / 100).toFixed(2);
}

function getTotalByItem(receipts, itemName) {
  let sum = 0;
  receipts.forEach((receipt) => {
    const foundItem = receipt.items.find((item) => item.name === itemName);
    if (foundItem) {
      sum += foundItem.price;
    }
  });
  sum = (sum / 100).toFixed(2);
  return sum;
}

export {
  groupReceiptsBySellerName,
  groupReceiptsByItemName,
  groupReceiptsByIssuedHour,
  filterReceiptsByTimestamp,
  filterReceiptsBySellerNames,
  filterReceiptsByLocationId,
  getTotal,
  getTotalTax,
  getTotalByItem,
};
