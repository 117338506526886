import { reactive } from 'vue';

const store = reactive({
  userId: null,
  setUserId(id) {
    this.userId = id;
    localStorage.setItem('userId', id);
  },
  getUserId() {
    return this.userId || localStorage.getItem('userId');
  },
  removeUserId() {
    localStorage.removeItem('userId');
  },

  user: null,
  getUser() {
    return this.user;
  },

  language: 'hr',
  setLanguage(language) {
    this.language = language;
    localStorage.setItem('appLanguage', language);
  },
  getLanguage() {
    return this.language || localStorage.getItem('appLanguage');
  },

  currentCompanyId: null,
  getCurrentCompanyId() {
    return this.currentCompanyId || localStorage.getItem('companyId');
  },
  setCurrentCompanyId(companyId) {
    this.currentCompanyId = companyId;
    this.currentLocationId = 'all_locations';
    localStorage.setItem('companyId', companyId);
  },
  allCompanies: [],
  getAllCompanies() {
    return this.allCompanies;
  },
  getCurrentCompany() {
    return this.allCompanies.find((company) => company.id === store.getCurrentCompanyId());
  },

  currentLocationId: 'all_locations',
  getCurrentLocationId() {
    return this.currentLocationId;
  },
  setCurrentLocationId(locationId) {
    this.currentLocationId = locationId;
  },
  companyLocations: [],
  getCompanyLocations() {
    return this.companyLocations;
  },

  currentWarehouse: null,
  getCurrentWarehouse() {
    return this.currentWarehouse;
  },
  setCurrentWarehouse(warehouse) {
    this.currentWarehouse = warehouse;
  },
  companyWarehouses: [],
  getLocationWarehoues() {
    if (this.currentLocationId === 'all_locations') {
      return this.companyWarehouses;
    } else {
      return this.companyWarehouses.filter(
        (warehouse) => warehouse.location_id === this.currentLocationId,
      );
    }
  },
});

export default store;
