<template>
  <v-btn color="primary" variant="text" size="large">
    <v-icon icon="mdi-tune"></v-icon>
    <v-dialog
      v-model="dialog"
      activator="parent"
      :fullscreen="fullscreen"
      :transition="fullscreen ? 'dialog-top-transition' : 'scroll-x-reverse-transition'"
      width="550"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon="mdi-arrow-left" @click="dialog = false"></v-btn>
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn variant="text" @click="onSearch" append-icon="mdi-magnify"
              >{{ $t('search') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list lines="two" subheader>
          <v-list-item :title="$t('warehouse.title')">
            <v-list-item-subtitle>
              <div class="d-flex justify-space-around align-center">
                <div class="w-75">{{ currentWarehouseSelected.name }}</div>
                <WarehouseSelect @warehouseChanged="onChangeWarehouse" />
              </div>
            </v-list-item-subtitle>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            :title="$t('warehouse.itemName')"
            :subtitle="$t('warehouse.itemNamesFilter')"
            class="mb-1"
          >
            <v-autocomplete
              v-model="itemsSelected"
              v-model:search="itemsSearch"
              :loading="loading"
              :items="warehouseItems"
              chips
              closable-chips
              multiple
              hide-no-data
              hide-details
              class="mt-4"
              density="compact"
              variant="outlined"
              item-title="name"
              item-value="name"
              :label="$t('warehouse.enterItemNames')"
            >
              <template v-slot:chip="{ props, item }">
                <v-chip v-bind="props" :text="item.raw.name"></v-chip>
              </template>

              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :title="item.raw.name"
                  :subtitle="`${$t('warehouse.inStock')}: ${item.raw.stock / 1000}`"
                ></v-list-item>
              </template>
            </v-autocomplete>
          </v-list-item>

          <v-list-item
            :title="$t('warehouse.suppliers')"
            :subtitle="$t('warehouse.suppliersFilter')"
          >
            <v-autocomplete
              v-model="supplierSelected"
              :items="suppliers"
              item-title="name"
              item-value="id"
              :label="$t('warehouse.enterSupplier')"
              class="mt-4"
              density="compact"
              variant="outlined"
              hide-no-data
              hide-details
              clearable
            >
              <template v-slot:item="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :title="item.raw.name"
                  :subtitle="item.raw.address + ', ' + item.raw.zip_code + ' ' + item.raw.city"
                ></v-list-item>
              </template>
            </v-autocomplete>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onSearch">
            {{ $t('search') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import WarehouseSelect from './WarehouseSelect.vue';
import { df } from '../../plugins/firebase';
import store from '../../store';
import { getSuppliers } from '../../queries';

export default {
  name: 'WarehouseFilterDialog',
  components: { WarehouseSelect },
  emits: ['search'],
  data() {
    return {
      dialog: false,
      suppliers: [],
      supplierSelected: null,
      searchOptions: {},
      warehouseItems: [],
      itemsSelected: null,
      itemsSearch: null,
      loading: false,
      currentWarehouseSelected: store.getCurrentWarehouse(),
    };
  },
  computed: {
    fullscreen() {
      return this.$vuetify.display.smAndDown;
    },
    items() {
      return this.warehouseItems.map((item) => item.item);
    },
  },
  watch: {
    itemsSearch(val) {
      val && val !== this.itemsSelected && this.querySelections(val);
    },
    itemsSelected() {
      this.itemsSearch = null;
      this.warehouseItems = [];
    },
  },
  mounted() {
    getSuppliers().then((resp) => (this.suppliers = resp));
  },
  methods: {
    onSearch() {
      this.searchOptions = {
        items: this.itemsSelected,
        supplier: this.suppliers.find((supplier) => supplier.id === this.supplierSelected),
      };
      this.$emit('search', this.searchOptions);
      store.setCurrentWarehouse(this.currentWarehouseSelected);
      this.dialog = false;
    },
    onChangeWarehouse(warehouse) {
      this.currentWarehouseSelected = warehouse;
    },
    querySelections(term) {
      term = term.toLowerCase();
      if (term.length < 3) return;

      const query = df
        .collection(`warehouses/${this.currentWarehouseSelected.id}/extended_items/`)
        .where('item.name', '>=', term)
        .where('item.name', '<=', term + '~');

      this.loading = true;
      query.get().then((docSnapshots) => {
        if (docSnapshots.docs && !docSnapshots.docs.empty) {
          this.warehouseItems = docSnapshots.docs.map((item) => item.data().item);
        }
        this.loading = false;
      });
    },
  },
};
</script>
