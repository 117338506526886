<template>
  <div v-if="balances.length > 0">
    <div class="text-subtitle-2 mb-2">
      <v-icon icon="mdi-cash-register"></v-icon>
      {{ title }}
    </div>
    <v-expansion-panels class="mb-3">
      <v-expansion-panel v-for="balance in balances" :key="balance.id">
        <v-expansion-panel-title expand-icon="mdi-plus" collapse-icon="mdi-minus">
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col cols="8" sm="4" md="3">
                <div class="mb-1 text-caption text-grey text-uppercase">
                  {{ $t('closingBalance.closedBy') }}
                </div>
                <div>{{ balance.requested_by.name + ' ' + balance.requested_by.surname }}</div>
              </v-col>
              <v-col cols="4" sm="3" md="2">
                <div class="mb-1 text-caption text-grey text-uppercase">
                  {{ $t('closingBalance.earnings') }}
                </div>
                <div>{{ currencyFormat(balance.cash_register.available_funds / 100) }}</div>
              </v-col>
              <v-col cols="4" sm="3" md="2">
                <div class="mb-1 text-caption text-grey text-uppercase">{{ $t('date') }}</div>
                <div>{{ dateFormat(new Date(balance.created * 1000)) }}</div>
              </v-col>
              <v-col cols="3" sm="2" md="2">
                <div class="mb-1 text-caption text-grey text-uppercase">{{ $t('time') }}</div>
                <div>{{ timeFormat(new Date(balance.created * 1000)) }}</div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col cols="3">
                <div class="mb-1 text-caption text-grey text-uppercase">
                  {{ $t('closingBalance.banknotes') }}
                </div>
                <div>{{ currencyFormat(balance.cash_register.available_funds / 100) }}</div>
                <div class="mb-1 text-caption text-grey text-uppercase">
                  {{ $t('closingBalance.cards') }}
                </div>
                <div>{{ currencyFormat(balance.cash_register.card_funds / 100) }}</div>
                <div class="mb-1 text-caption text-grey text-uppercase">
                  {{ $t('closingBalance.other') }}
                </div>
                <div>{{ currencyFormat(balance.cash_register.other_funds / 100) }}</div>
              </v-col>
              <v-col cols="9">
                <div class="mx-6 my-2 chart-wrapper">
                  <FundsChart
                    :data="[
                      balance.cash_register.available_funds / 100,
                      balance.cash_register.card_funds / 100,
                      balance.cash_register.other_funds / 100,
                    ]"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <div class="mb-1 text-caption text-grey text-uppercase">
                  {{ $t('closingBalance.deposit') }}
                </div>
                <div>{{ currencyFormat(balance.deposit?.amt / 100) }}</div>
              </v-col>
              <v-col cols="8">
                <div class="mb-1 text-caption text-grey text-uppercase">
                  {{ $t('closingBalance.previous') }}
                </div>
                <div>{{ dateTimeFormat(new Date(balance.from * 1000)) }}</div>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <v-alert v-else-if="loaded" type="warning" :title="$t('noResults')" border="top">
    {{ $t('closingBalance.noClosingDataMsg1') }} <span class="font-weight-bold">{{ title }}</span
    >{{ $t('closingBalance.noClosingDataMsg2') }}
    <span class="font-weight-bold">{{ dateFormat(closingDate) }}</span>
  </v-alert>
</template>

<script>
import { getBalancesForCashRegister } from '../../queries/index';
import { dateFormat, timeFormat, dateTimeFormat, currencyFormat } from '../../utils/util';
import FundsChart from './FundsChart.vue';

export default {
  name: 'ClosingBalanceList',
  inject: ['hideLoader', 'showLoader'],
  props: ['cashRegister', 'closingDate'],
  components: { FundsChart },
  data() {
    return {
      balances: [],
      loaded: false,
    };
  },
  computed: {
    title() {
      return this.cashRegister.nameAndLocation;
    },
  },
  created() {
    this.$watch(
      () => this.cashRegister.id,
      () => {
        this.getBalances();
      },
    );
    this.$watch(
      () => this.closingDate,
      () => {
        this.getBalances();
      },
    );
  },
  mounted() {
    this.getBalances();
  },
  methods: {
    async getBalances() {
      this.showLoader();
      this.balances = await getBalancesForCashRegister(this.cashRegister.id, this.closingDate);
      this.loaded = true;
      this.hideLoader();
    },
    dateFormat,
    timeFormat,
    dateTimeFormat,
    currencyFormat,
  },
};
</script>

<style scoped>
.chart-wrapper {
  height: 180px;
  width: 200px;
  padding: 8px 16px;
}
</style>
