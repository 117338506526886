<template>
  <BasePage :title="title">
    <template #actions>
      <WarehouseFilterDialog @search="searchTriggered" />
    </template>
    <template #content>
      <div class="mb-1 d-flex flex-wrap">
        <v-chip
          v-for="(item, i) in queryFilters.items"
          :key="i"
          variant="text"
          color="primary"
          class="mr-1 mb-1"
          size="small"
        >
          {{ item }}
        </v-chip>
      </div>
      <WarehouseItemsList
        :key="componentKey"
        :warehouseId="currentWarehouse.id"
        :queryFilters="queryFilters"
      />
      <div v-if="showEmptyMessage" class="text-grey">
        {{ $t('warehouse.emptyMessage') }}
      </div>
    </template>
  </BasePage>
</template>

<script>
import WarehouseItemsList from '../modules/warehouse/WarehouseItemsList.vue';
import store from '../store';
import WarehouseFilterDialog from '../modules/warehouse/WarehouseFilterDialog.vue';
import BasePage from '../components/BasePage.vue';
import locationChangeWatcher from '../mixins/locationChangeWatcher';
import companyChangeWatcher from '../mixins/companyChangeWatcher';

export default {
  components: { WarehouseItemsList, WarehouseFilterDialog, BasePage },
  data() {
    return {
      componentKey: 0,
      queryFilters: {},
    };
  },
  computed: {
    currentWarehouse() {
      return store.getCurrentWarehouse();
    },
    title() {
      return this.$t('warehouse.title') + ' - ' + this.currentWarehouse?.name;
    },
    showEmptyMessage() {
      return store.getLocationWarehoues().length === 0;
    },
  },
  mixins: [companyChangeWatcher, locationChangeWatcher],
  methods: {
    onCompanyChangeUpdate() {
      this.componentKey += 1;
    },
    onLocationChangeUpdate() {
      this.componentKey += 1;
    },
    searchTriggered(searchParams) {
      this.queryFilters = searchParams;
      this.componentKey += 1;
    },
  },
};
</script>
