<template>
  <v-dialog v-model="dialog" scrollable :class="{ 'w-50': this.$vuetify.display.smAndUp }">
    <template v-slot:activator="{ props }">
      <v-btn block color="primary" variant="outlined" v-bind="props">
        {{ currentCashRegister }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t('closingBalance.select') }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-radio-group v-model="radioSelect" column>
          <v-radio
            v-for="(cashRegister, i) in cashRegisters"
            :key="cashRegister.id"
            :value="i"
            :label="cashRegister.nameAndLocation"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" variant="text" @click="closeDialog">{{ $t('close') }}</v-btn>
        <v-btn color="primary" variant="text" @click="changeCashRegister">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { truncateString } from '../../utils/util';

export default {
  name: 'CashRegisterSelect',
  emits: ['cashRegisterChanged'],
  props: ['cashRegisters'],
  data() {
    return {
      radioSelect: 0,
      currentRegisterIndex: 0,
      dialog: false,
    };
  },
  computed: {
    currentCashRegister() {
      return truncateString(this.cashRegisters[this.currentRegisterIndex].name, 25);
    },
  },
  methods: {
    changeCashRegister() {
      this.currentRegisterIndex = this.radioSelect;
      this.$emit('cashRegisterChanged', this.cashRegisters[this.currentRegisterIndex]);
      this.dialog = false;
    },
    closeDialog() {
      this.radioSelect = this.currentRegisterIndex;
      this.dialog = false;
    },
  },
};
</script>
