<template>
  <v-container class="pt-0 pt-md-2 pt-lg-4">
    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="10" lg="8">
        <div class="d-flex justify-space-between align-center title-bar">
          <h3 class="text-uppercase text-grey-darken-1">
            {{ title }}
          </h3>
          <slot name="actions"></slot>
        </div>
        <slot name="content"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BasePage',
  props: ['title'],
};
</script>

<style scoped>
.title-bar {
  height: 50px;
}
</style>
