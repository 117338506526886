<template>
  <DoughnutChart
    v-if="showChart"
    :chartData="chartData"
    :options="chartOptions"
    :height="chartHeight"
  />
  <div v-else class="text-grey text-center pt-10">{{ $t('charts.noData') }}</div>
</template>

<script>
import { DoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { currencyFormat, truncateString } from '../../utils/util';

Chart.register(...registerables);

export default {
  name: 'dashboardDoughnutChart',
  components: { DoughnutChart },
  props: ['providedData'],
  computed: {
    chartData() {
      return {
        datasets: [
          {
            data: this.providedData.data,
          },
        ],
        labels: this.providedData.labels.map((label) => truncateString(label, 15)),
      };
    },
    chartHeight() {
      return this.$vuetify.display.mdAndUp ? 250 : 210;
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
          },
          tooltip: {
            callbacks: {
              title: (context) => {
                const data = context[0].dataset.data[context[0].dataIndex];
                context[0].formattedValue = currencyFormat(data);
              },
            },
          },
          colors: {
            forceOverride: true,
          },
        },
      };
    },
    showChart() {
      return this.chartData.labels.length > 0 && this.chartData.datasets[0].data.length > 0;
    },
  },
};
</script>
