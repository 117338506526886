export default {
  language: 'Language',
  languages: {
    croatian: 'Croatian',
    english: 'English',
  },
  home: 'Home',
  revenue: 'Revenue',
  earnings: 'Earnings',
  financeReports: 'Financial Reports',
  profile: 'Profile',
  companies: 'Companies',
  locations: 'Locations',
  allLocations: 'All Locations',
  settings: 'Settings',
  help: 'Help',
  close: 'Close',
  save: 'Save',
  search: 'Search',
  change: 'Change',
  details: 'Details',
  login: 'Login',
  logout: 'Logout',
  password: 'Password',
  date: 'Date',
  time: 'Time',
  noResults: 'No results',
  cashRegister: 'Cash Register',
  dashboard: {
    today: 'Today',
    thisWeek: 'This week',
    thisMonth: 'This month',
    weeklyOverview: 'Weekly Overview',
    revenueBySeller: 'Revenue by Seller',
    revenueByItem: 'Revenue by Item',
    revenueInDay: 'Revenue in Day',
    total: 'Total',
    tax: 'Tax',
    difference: 'Difference',
    viewSales: 'View Sales',
    selectEmployee: 'Select employee',
    other: 'Other',
  },
  warehouse: {
    title: 'Warehouse',
    stock: 'Warehouse Stock',
    select: 'Select Warehouse',
    itemName: 'Item Name',
    inStock: 'In Stock',
    description: 'Item description',
    code: 'Code',
    emptyMessage: 'No warehouses registered yet.',
    itemNamesFilter: 'Filter by item names',
    enterItemNames: 'Enter items...',
    suppliers: 'Suppliers',
    suppliersFilter: 'Filter by supplier',
    enterSupplier: 'Enter supplier...',
  },
  reports: {
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    difference: 'Difference',
    base: 'Base',
    tax: 'Tax',
  },
  closingBalance: {
    title: 'Closing Balance',
    closedBy: 'Closed by',
    earnings: 'Earnings',
    banknotes: 'Banknotes',
    cards: 'Cards',
    other: 'Other',
    deposit: 'Deposit',
    previous: 'Last closing',
    select: 'Select Cash Register',
    noClosingDataMsg1: 'No closing data for cash register',
    noClosingDataMsg2: ' on day ',
    noCashRegisterMsg: "You don't have any cash registers.",
  },
  charts: {
    noData: 'No data to show',
  },
  messages: {
    requiredField: 'Required field',
    invalidEmail: 'Enter a valid e-mail address',
    unconfirmedEmail: "You haven't confirmed you e-mail address yet",
    cantUseApp: "Unfortunatelly, you can't use the App",
    appAdmin: 'Reach out to Customer Service',
    appRest: 'Reach out to your Administrator',
  },
  errors: {
    noCorrespondingUser:
      'There is no user record corresponding to this identifier. The user may have been deleted.',
    wrongPassword: 'The password is invalid or the user does not have a password.',
    tooManyRequests:
      'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
  },
};
