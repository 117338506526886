import store from '../store';

function truncateString(str, n) {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
}

function dateToSeconds(date) {
  return parseInt((date.getTime() / 1000).toFixed(0));
}

function startOfDay(d = null) {
  let date = d === null ? new Date() : new Date(d);
  date.setHours(0, 0, 0, 0);
  return date;
}

function endOfDay(d = null) {
  let date = d === null ? new Date() : new Date(d);
  date.setHours(23, 59, 59, 999);
  return date;
}

function startOfWeek(d = null) {
  let date = d === null ? new Date() : new Date(d);
  const temp = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  date.setDate(temp);
  date.setHours(0, 0, 0, 0);
  return date;
}

function startOfMonth(d = null) {
  let date = d === null ? new Date() : new Date(d);
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
}

function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1, 0, 0, 0, 0);
}

function getMonthAndYear(month, year, options) {
  const date = new Date();

  date.setMonth(month);
  date.setFullYear(year, month);

  const optionsLocale = {
    month: options.month ?? 'long',
    year: options.year ?? 'numeric',
  };

  return date.toLocaleString(store.getLanguage(), optionsLocale);
}

function getCurrentYear() {
  const date = new Date();
  return date.getFullYear();
}

function currencyFormat(number) {
  return new Intl.NumberFormat(store.getLanguage(), {
    style: 'currency',
    currency: 'EUR',
  }).format(isNaN(number) ? 0 : number);
}

function dateFormat(date) {
  return date.toLocaleDateString(store.getLanguage(), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

function timeFormat(date) {
  return date.toLocaleTimeString(store.getLanguage(), {
    hour: '2-digit',
    minute: '2-digit',
  });
}

function dateTimeFormat(date) {
  return date.toLocaleString(store.getLanguage(), {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export {
  truncateString,
  dateToSeconds,
  startOfDay,
  endOfDay,
  startOfWeek,
  startOfMonth,
  getFirstDayOfMonth,
  getMonthAndYear,
  getCurrentYear,
  currencyFormat,
  dateFormat,
  timeFormat,
  dateTimeFormat,
};
