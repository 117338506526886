<template>
  <BasePage :title="$t('closingBalance.title')">
    <template #actions>
      <DatePicker @onDateChange="updateDate" />
    </template>
    <template #content>
      <div v-if="cashRegistersList.length > 0">
        <div class="mb-4">
          <div class="ml-1" :class="{ 'w-25': this.$vuetify.display.smAndUp }">
            <div class="mb-1 text-caption text-grey text-uppercase">{{ $t('cashRegister') }}</div>
            <CashRegisterSelect
              :cashRegisters="cashRegistersList"
              @cashRegisterChanged="changeCashRegister"
            />
          </div>
        </div>
        <ClosingBalanceList :cashRegister="selectedCashRegister" :closingDate="date" />
      </div>
      <div v-else class="text-grey">{{ $t('closingBalance.noCashRegisterMsg') }}</div>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '../components/BasePage.vue';
import { getCashRegisters } from '../queries/index';
import store from '../store';
import { dateFormat } from '../utils/util';
import ClosingBalanceList from '../modules/closingBalance/ClosingBalanceList.vue';
import DatePicker from '../components/DatePicker.vue';
import CashRegisterSelect from '../modules/closingBalance/CashRegisterSelect.vue';
import companyChangeWatcher from '../mixins/companyChangeWatcher';
import locationChangeWatcher from '../mixins/locationChangeWatcher';

export default {
  components: { BasePage, ClosingBalanceList, DatePicker, CashRegisterSelect },
  inject: ['hideLoader', 'showLoader'],
  data() {
    return {
      cashRegisters: [],
      selectedCashRegister: { name: '', location: '' },
      date: new Date(),
    };
  },
  computed: {
    cashRegistersList() {
      const locationId = store.getCurrentLocationId();
      if (locationId === 'all_locations') {
        return this.cashRegisters;
      } else {
        return this.cashRegisters.filter((cashRegister) => cashRegister.locationId === locationId);
      }
    },
  },
  mounted() {
    this.getCashRegisters();
  },
  mixins: [companyChangeWatcher, locationChangeWatcher],
  methods: {
    onCompanyChangeUpdate() {
      this.getCashRegisters();
    },
    onLocationChangeUpdate() {
      this.selectedCashRegister = this.cashRegistersList[0];
    },
    async getCashRegisters() {
      this.showLoader();
      this.cashRegisters = await getCashRegisters(store.getCurrentCompanyId());
      if (this.cashRegisters.length > 0) {
        this.selectedCashRegister = this.cashRegisters[0];
      }
      this.hideLoader();
    },
    changeCashRegister(payload) {
      this.selectedCashRegister = payload;
    },
    updateDate(payload) {
      this.date = payload;
    },
    dateFormat,
  },
};
</script>
