import store from '../store';

export default {
  created() {
    this.$watch(
      () => store.getCurrentLocationId(),
      () => {
        this.onLocationChangeUpdate();
      },
    );
  },
};
