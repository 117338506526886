<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" icon="mdi-dots-vertical"></v-btn>
    </template>
    <v-card class="mx-auto" max-width="95vw" min-width="250px">
      <v-list>
        <v-list-item :title="fullName" :subtitle="currentCompany">
          <template v-slot:prepend>
            <v-avatar color="secondary" size="large">
              <span class="text-h5">{{ initials }}</span>
            </v-avatar>
          </template>
          <template v-slot:append>
            <v-btn variant="text" size="small" icon="mdi-close" @click="menu = false"></v-btn>
          </template>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item v-for="(item, i) in menuItems" :key="i" :value="item">
          <template v-slot:prepend>
            <v-icon :icon="item.icon"></v-icon>
          </template>

          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
        <!-- <v-divider></v-divider> -->

        <v-list-item>
          <v-btn @click="logout" color="primary" class="mt-2" prepend-icon="mdi-exit-to-app">{{
            $t('logout')
          }}</v-btn>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import store from '../store';
import { endSession } from '../plugins/auth';
import { truncateString } from '../utils/util';

export default {
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    fullName() {
      const user = store.getUser();
      return user.name + ' ' + user.surname;
    },
    initials() {
      const user = store.getUser();
      return user.name.substring(0, 1);
    },
    currentCompany() {
      return truncateString(store.getCurrentCompany().name, 25);
    },
    menuItems() {
      return [
        //{ text: this.$t('profile'), icon: 'mdi-account' },
        //{ text: this.$t('companies'), icon: 'mdi-factory' },
        //{ text: this.$t('settings'), icon: 'mdi-cog' },
        //{ text: this.$t('help'), icon: 'mdi-progress-question' },
      ];
    },
  },
  methods: {
    logout() {
      endSession();
      this.$router.push({ name: 'login' });
      store.removeUserId();
    },
  },
};
</script>
