<template>
  <AppLayout disableNavMenu="true">
    <v-div class="h-100 d-flex align-center">
      <v-sheet
        v-if="visible"
        elevation="12"
        max-width="600"
        rounded="lg"
        width="90%"
        height="fit-content"
        class="pa-4 text-center mx-auto"
      >
        <v-icon class="mb-5" color="warning" icon="mdi-alert" size="100"></v-icon>
        <h2 class="text-h6 text-grey mb-6">{{ $t('messages.cantUseApp') }}</h2>
        <div class="mb-4 text-medium-emphasis text-body-2 text-grey">
          <span v-if="isSuperadmin">{{ $t('messages.appAdmin') }}</span>
          <span v-else>{{ $t('messages.appRest') }}</span>
        </div>
        <v-divider class="mb-4"></v-divider>
        <div class="text-end">
          <v-btn
            class="text-none"
            color="warning"
            rounded
            variant="flat"
            width="90"
            @click="visible = false"
          >
            OK
          </v-btn>
        </div>
      </v-sheet>
    </v-div>
  </AppLayout>
</template>

<script>
import AppLayout from '../components/AppLayout.vue';
import store from '../store';

export default {
  components: { AppLayout },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    isSuperadmin() {
      const user = store.getUser();
      return user.roles['SUPERADMIN'];
    },
  },
};
</script>
