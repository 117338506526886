import { createRouter, createWebHistory } from 'vue-router';

import LoginView from './views/LoginView';
import HomeView from './views/HomeView';
import DashboardView from './views/DashboardView';
import WarehouseView from './views/WarehouseView';
import ReportsView from './views/ReportsView';
import DemoView from './views/DemoView';
import ClosingBalanceView from './views/ClosingBalanceView';

import { auth } from './plugins/firebase';
import { getCompanies, getLocations, getUser, getWarehouses } from './queries';
import store from './store';

const routes = [
  { path: '/login', name: 'login', component: LoginView },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: async (to, from, next) => {
      store.user = await getUser();
      store.allCompanies = await getCompanies();
      let companyId = store.getCurrentCompanyId();
      if (!companyId && store.allCompanies.length > 0) {
        store.setCurrentCompanyId(store.allCompanies[0].id);
        companyId = store.getCurrentCompanyId();
      }

      store.companyLocations = await getLocations(companyId);
      store.companyWarehouses = await getWarehouses(companyId);
      if (store.companyWarehouses.length > 0) {
        store.setCurrentWarehouse(store.companyWarehouses[0]);
      }

      let canUseTheApp = false;
      if (store.user.za_gazde) {
        canUseTheApp = store.user.za_gazde.active;
        if (store.user.za_gazde.active_to) {
          const now = new Date();
          canUseTheApp &&= new Date(store.user.za_gazde.active_to * 1000) >= now;
        }
      }
      if (canUseTheApp) {
        next();
      } else {
        next({ name: 'demo' });
      }
    },
    children: [
      { path: '/dashboard', name: 'dashboard', component: DashboardView },
      { path: '/warehouse', name: 'warehouse', component: WarehouseView },
      { path: '/reports', name: 'reports', component: ReportsView },
      { path: '/closing-balance', name: 'closingBalance', component: ClosingBalanceView },
    ],
    redirect: { name: 'dashboard' },
  },
  {
    path: '/demo',
    name: 'demo',
    component: DemoView,
    beforeEnter: async () => {
      store.user = await getUser();
      store.allCompanies = await getCompanies();
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = auth.currentUser && auth.currentUser.emailVerified;
  if (to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
