<template>
  <v-menu v-model="menu" :close-on-content-click="false" location="end">
    <template v-slot:activator="{ props }">
      <v-btn color="primary" variant="text" size="large" v-bind="props">
        <v-icon icon="mdi-calendar-edit"></v-icon>
      </v-btn>
    </template>
    <v-card>
      <VueDatePicker
        v-model="date"
        v-bind="$props"
        inline
        :max-date="new Date()"
        :enable-time-picker="false"
        :clearable="false"
        :format="dateFormat"
        :locale="locale"
      >
        <template #action-row="{ selectDate, closePicker }">
          <div class="w-100 d-flex justify-space-around">
            <v-btn color="primary" @click="changeDate(selectDate)">{{ $t('save') }}</v-btn>
            <v-btn color="primary" variant="outlined" @click="close(closePicker)">{{
              $t('close')
            }}</v-btn>
          </div>
        </template>
      </VueDatePicker>
    </v-card>
  </v-menu>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import store from '../store';
import { dateFormat } from '../utils/util';
export default {
  name: 'DatePicker',
  components: { VueDatePicker },
  props: ['initialDate', 'monthPicker'],
  emits: ['OnDateChange'],
  data() {
    return {
      date: this.initialDate ?? new Date(),
      menu: false,
    };
  },
  computed: {
    locale() {
      return store.getLanguage();
    },
  },
  methods: {
    changeDate(cb) {
      cb();
      this.menu = false;
      this.$emit('OnDateChange', this.date);
    },
    close(cb) {
      cb();
      this.menu = false;
    },
    dateFormat,
  },
};
</script>

<style>
.dp__theme_light {
  --dp-primary-color: rgb(var(--v-theme-primary));
}
</style>
