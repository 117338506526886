<template>
  <v-menu v-if="showLocationsSelect" location="bottom" :close-on-content-click="true">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" icon="mdi-map-marker-radius"></v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-subheader class="text-uppercase">{{ $t('locations') }}</v-list-subheader>
        <v-list-item
          :active="'all_locations' === currentLocationId"
          :title="$t('allLocations')"
          active-color="primary"
          @click="changeLocation('all_locations')"
        >
          <template v-slot:prepend>
            <v-icon :icon="'all_locations' === currentLocationId ? 'mdi-check-bold' : ''"></v-icon>
          </template>
        </v-list-item>
        <v-list-item
          v-for="location in locations"
          :key="location.id"
          :active="location.id === currentLocationId"
          :title="location.name"
          active-color="primary"
          @click="changeLocation(location.id)"
        >
          <template v-slot:prepend>
            <v-icon :icon="location.id === currentLocationId ? 'mdi-check-bold' : ''"></v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import store from '../store';
import { truncateString } from '../utils/util';

export default {
  name: 'LocationSelect',
  computed: {
    showLocationsSelect() {
      return store.getCompanyLocations().length > 1;
    },
    locations() {
      const companyLocations = store.getCompanyLocations();
      return companyLocations
        .map((location) => {
          return {
            ...location,
            name: truncateString(location.name, 25),
          };
        })
        .sort((location1, location2) => {
          return location1.name < location2.name ? -1 : 1;
        });
    },
    currentLocationId() {
      return store.getCurrentLocationId();
    },
  },
  methods: {
    changeLocation(locationId) {
      store.setCurrentLocationId(locationId);
      const locationWarehouses = store.getLocationWarehoues();
      if (locationWarehouses.length > 0) {
        store.setCurrentWarehouse(locationWarehouses[0]);
      }
    },
  },
};
</script>
