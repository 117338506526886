<template>
  <v-dialog v-model="dialog" scrollable :class="{ 'w-25': this.$vuetify.display.smAndUp }">
    <template v-slot:activator="{ props }">
      <v-btn color="primary" variant="text" v-bind="props" icon="mdi-plus-box"> </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t('warehouse.select') }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-radio-group v-model="radioSelect" column>
          <v-radio
            v-for="(warehouse, i) in warehouses"
            :key="warehouse.id"
            :value="i"
            :label="warehouse.name"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" variant="text" @click="closeDialog">{{ $t('close') }}</v-btn>
        <v-btn color="primary" variant="text" @click="changeWarehouse">{{ $t('save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '../../store';

export default {
  name: 'WarehouseSelect',
  emits: ['warehouseChanged'],
  data() {
    return {
      radioSelect: 0,
      currentWarehouseIndex: 0,
      dialog: false,
    };
  },
  computed: {
    warehouses() {
      return store.getLocationWarehoues();
    },
  },
  methods: {
    changeWarehouse() {
      this.currentWarehouseIndex = this.radioSelect;
      this.$emit('warehouseChanged', this.warehouses[this.currentWarehouseIndex]);
      this.dialog = false;
    },
    closeDialog() {
      this.radioSelect = this.currentWarehouseIndex;
      this.dialog = false;
    },
  },
};
</script>
