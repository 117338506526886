export default {
  language: 'Jezik',
  languages: {
    croatian: 'Hrvatski',
    english: 'Engleski',
  },
  home: 'Početna',
  revenue: 'Promet',
  earnings: 'Zarada',
  financeReports: 'Financijski izvještaj',
  profile: 'Profil',
  companies: 'Firme',
  locations: 'Mjesta',
  allLocations: 'Sva mjesta',
  settings: 'Postavke',
  help: 'Pomoć',
  close: 'Zatvori',
  save: 'Spremi',
  search: 'Pretraži',
  change: 'Promijeni',
  details: 'Detaljnije',
  login: 'Prijava',
  logout: 'Odjava',
  password: 'Lozinka',
  date: 'Datum',
  time: 'Vrijeme',
  noResults: 'Nema rezultata',
  cashRegister: 'Blagajna',
  dashboard: {
    today: 'Danas',
    thisWeek: 'Ovaj tjedan',
    thisMonth: 'Ovaj mjesec',
    weeklyOverview: 'Tjedni pregled',
    revenueBySeller: 'Promet po zaposlenicima',
    revenueByItem: 'Promet po proizvodima',
    revenueInDay: 'Promet u danu',
    total: 'Ukupno',
    tax: 'PDV',
    difference: 'Razlika',
    viewRevenue: 'Vidi Zaradu',
    selectEmployee: 'Odaberi zaposlenika',
    other: 'Ostalo',
  },
  warehouse: {
    title: 'Skladište',
    stock: 'Stanje skladišta',
    select: 'Odaberi skladište',
    itemName: 'Naziv artikla',
    inStock: 'Količina',
    description: 'Opis',
    code: 'Kod',
    emptyMessage: 'Nema evidentiranih skladišta.',
    itemNamesFilter: 'Pretraži po artiklima',
    enterItemNames: 'Unesi artikle...',
    suppliers: 'Dobavljači',
    suppliersFilter: 'Pretraži po dobavljačima',
    enterSupplier: 'Unesi dobavljača...',
  },
  reports: {
    incoming: 'Ulazni',
    outgoing: 'Izlazni',
    difference: 'Razlika',
    base: 'Osnovica',
    tax: 'Porez',
  },
  closingBalance: {
    title: 'Zaključak blagajne',
    closedBy: 'Blagajnu zaključio',
    earnings: 'Utržak',
    banknotes: 'Novčanice',
    cards: 'Kartice',
    other: 'Ostalo',
    deposit: 'Polog',
    previous: 'Prethodni zaključak',
    select: 'Odaberi blagajnu',
    noClosingDataMsg1: 'Nema zaključaka za blagajnu ',
    noClosingDataMsg2: ' na dan ',
    noCashRegisterMsg: 'Nemate registriranu niti jednu blagajnu',
  },
  charts: {
    noData: 'Nema podataka za prikaz',
  },
  messages: {
    requiredField: 'Obavezno polje',
    invalidEmail: 'Unesite valjanu e-mail adresu',
    unconfirmedEmail: 'Još niste potvrdili e-mail adresu!',
    cantUseApp: 'Nažalost, ne možete koristiti aplikaciju',
    appAdmin: 'Javite se službi za korisnike',
    appRest: 'Javite se administratoru za uključenje aplikacije',
  },
  errors: {
    noCorrespondingUser: 'Email ili lozinka nisu ispravni.',
    wrongPassword: 'Email ili lozinka nisu ispravni.',
    tooManyRequests:
      'Pristup vašem računu trenutno je blokiran zbog previše pokušaja upisa lozinke. Možete odmah dobiti pristup ako promjenite vašu lozinku ili pokušajte se ulogirati kasnije.',
  },
};
