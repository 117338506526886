<template>
  <number
    :from="Math.max(0, value - 10)"
    :to="value"
    :format="currencyFormat"
    :duration="0.35"
    easing="Power1.easeOut"
  />
</template>

<script>
import store from '../store';

export default {
  name: 'AnimatedNumber',
  props: ['value'],
  methods: {
    currencyFormat(number) {
      return new Intl.NumberFormat(store.getLanguage(), {
        style: 'currency',
        currency: 'EUR',
      }).format(number);
    },
  },
};
</script>
