<template>
  <v-btn-toggle v-model="selectedItem" mandatory density="compact">
    <v-btn
      v-for="item in toggleGroup"
      :key="item.name"
      size="x-small"
      variant="text"
      rounded="xl"
      class="text-none text-primary"
      :ripple="false"
      :value="item.value"
      @click="this.$emit('update:selectedItem', timePoint)"
    >
      {{ item.name }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { startOfDay, startOfWeek, startOfMonth } from '../utils/util';

export default {
  name: 'PeriodSelect',
  emits: ['update:selectedItem'],
  data() {
    return {
      selectedItem: 'today',
    };
  },
  computed: {
    toggleGroup() {
      return [
        { name: this.$t('dashboard.today'), value: 'today' },
        { name: this.$t('dashboard.thisWeek'), value: 'week' },
        { name: this.$t('dashboard.thisMonth'), value: 'month' },
      ];
    },
    timePoint() {
      switch (this.selectedItem) {
        case 'today':
          return startOfDay();
        case 'week':
          return startOfWeek();
        case 'month':
          return startOfMonth();
        default:
          return startOfDay();
      }
    },
  },
};
</script>
