<template>
  <BasePage v-if="loaded" :title="currentCompany.name">
    <template #content>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="mx-auto" height="100%">
            <v-card-item>
              <div class="text-overline text-uppercase mb-1">{{ $t('revenue') }}</div>
              <div class="d-flex justify-end">
                <PeriodSelect
                  @update:selectedItem="(payload) => (profitCard.selectedTimespan = payload)"
                />
              </div>
              <div class="d-flex flex-column align-center mt-2">
                <div
                  v-for="(item, i) in profitCardItems"
                  :key="i"
                  class="profit-card text-surface text-h6 mb-4 ml-4 pa-2 pt-2 rounded-xl"
                  :class="item.cssClass"
                >
                  <div class="text-overline text-uppercase pl-4">
                    {{ item.label }}
                  </div>
                  <div class="pl-6">
                    <AnimatedNumber :value="item.value" />
                  </div>
                </div>
              </div>
            </v-card-item>
            <v-card-actions v-if="false"
              ><v-btn variant="text" color="primary" :to="{ name: 'earnings' }">{{
                $t('dashboard.viewRevenue')
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          <v-card height="100%" :min-height="cardMinHeight">
            <v-card-item class="align-items-center">
              <div class="text-overline text-uppercase mb-1">
                {{ $t('dashboard.revenueBySeller') }}
              </div>
              <div class="d-flex justify-end">
                <PeriodSelect
                  @update:selectedItem="(payload) => (pieChartCard.selectedTimespan = payload)"
                />
              </div>
              <DashboardDoughnutChart v-if="loaded" :providedData="profitPieData" />
            </v-card-item>
          </v-card>
        </v-col>
        <v-col cols="12" sm="8" md="6">
          <v-card height="100%" :min-height="cardMinHeight">
            <v-card-item>
              <div class="text-overline text-uppercase mb-1">
                {{ $t('dashboard.revenueByItem') }}
              </div>
              <div class="d-flex justify-end">
                <PeriodSelect
                  @update:selectedItem="(payload) => (itemsPieCard.selectedTimespan = payload)"
                />
              </div>
              <v-select
                v-if="employees.length > 1"
                class="mt-2"
                v-model="selectedEmployees"
                clearable
                chips
                :label="$t('dashboard.selectEmployee')"
                :items="employees"
                multiple
                variant="outlined"
                density="compact"
              ></v-select>
              <DashboardDoughnutChart v-if="loaded" :providedData="profitByItemsPieData" />
            </v-card-item>
          </v-card>
        </v-col>

        <v-col cols="12" sm="8" md="6">
          <v-card height="100%" :min-height="cardMinHeight">
            <v-card-item>
              <div class="text-overline text-uppercase mb-1">
                {{ $t('dashboard.revenueInDay') }}
              </div>
              <div class="d-flex justify-end">
                <PeriodSelect
                  @update:selectedItem="(payload) => (profitInDay.selectedTimespan = payload)"
                />
              </div>
              <DashboardLineChart :providedData="profitInTheDay" :hideLegend="true" />
            </v-card-item>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </BasePage>
</template>

<script>
import store from '../store';
import { df } from '../plugins/firebase';
import { dateToSeconds, startOfDay, startOfMonth, startOfWeek } from '../utils/util';
import {
  groupReceiptsBySellerName,
  groupReceiptsByItemName,
  groupReceiptsByIssuedHour,
  filterReceiptsByTimestamp,
  filterReceiptsBySellerNames,
  getTotal,
  getTotalTax,
  getTotalByItem,
  filterReceiptsByLocationId,
} from '../utils/receipts';
import PeriodSelect from '../components/PeriodSelect.vue';
import AnimatedNumber from '../components/AnimatedNumber.vue';
import BasePage from '../components/BasePage.vue';
import DashboardLineChart from '../modules/dashboard/DashboardLineChart.vue';
import DashboardDoughnutChart from '../modules/dashboard/DashboardDoughnutChart.vue';
import companyChangeWatcher from '../mixins/companyChangeWatcher';

export default {
  name: 'DashboardView',
  components: {
    PeriodSelect,
    AnimatedNumber,
    BasePage,
    DashboardLineChart,
    DashboardDoughnutChart,
  },
  inject: ['hideLoader', 'showLoader'],
  data() {
    return {
      allReceipts: [],
      profitCard: {
        selectedTimespan: startOfDay(),
      },
      pieChartCard: {
        selectedTimespan: startOfDay(),
      },
      itemsPieCard: {
        selectedTimespan: startOfDay(),
      },
      profitInDay: {
        selectedTimespan: startOfDay(),
      },
      loaded: false,
      selectedEmployees: [],
      receiptsListenerUnsubscribe: null,
    };
  },
  computed: {
    cardMinHeight() {
      let minHeight = '300px';
      if (this.$vuetify.display.lgAndUp) {
        minHeight = '350px';
      } else if (this.$vuetify.display.mdAndUp) {
        minHeight = '320px';
      }
      return minHeight;
    },
    currentCompany() {
      return store.getCurrentCompany();
    },
    receiptsByLocation() {
      const locationId = store.getCurrentLocationId();
      if (locationId === 'all_locations') {
        return this.allReceipts;
      } else {
        const location = store.companyLocations.find((loc) => loc.id === locationId);
        return filterReceiptsByLocationId(this.allReceipts, location.location_id);
      }
    },
    filteredReceiptsByTypeAndStatus() {
      return this.receiptsByLocation.filter((receipt) => {
        return ['PAID', 'REVERSED'].includes(receipt.payment.current_status);
      });
    },
    profitCardItems() {
      const filteredReceipts = filterReceiptsByTimestamp(
        this.filteredReceiptsByTypeAndStatus,
        'after',
        dateToSeconds(this.profitCard.selectedTimespan),
      );
      const total = getTotal(filteredReceipts);
      const tax = getTotalTax(filteredReceipts);
      const difference = (total - tax).toFixed(2);

      return [
        { value: total, label: this.$t('dashboard.total'), cssClass: 'revenue' },
        { value: tax, label: this.$t('dashboard.tax'), cssClass: 'tax' },
        { value: difference, label: this.$t('dashboard.difference'), cssClass: 'difference' },
      ];
    },
    revenueBySellerMap() {
      return groupReceiptsBySellerName(this.filteredReceiptsByTypeAndStatus);
    },
    revenueByItemsMap() {
      return groupReceiptsByItemName(this.filteredReceiptsByTypeAndStatus);
    },
    profitPieData() {
      const labels = [];
      const data = [];

      this.revenueBySellerMap.forEach((receipts, itemName) => {
        const filteredReceipts = filterReceiptsByTimestamp(
          receipts,
          'after',
          dateToSeconds(this.pieChartCard.selectedTimespan),
        );
        if (filteredReceipts.length > 0) {
          data.push(getTotal(filteredReceipts));
          labels.push(itemName);
        }
      });

      return {
        data,
        labels,
      };
    },
    profitByItemsPieData() {
      const data = [];

      this.revenueByItemsMap.forEach((receipts, itemName) => {
        let filteredReceipts;
        filteredReceipts = filterReceiptsByTimestamp(
          receipts,
          'after',
          dateToSeconds(this.itemsPieCard.selectedTimespan),
        );
        if (this.selectedEmployees.length > 0) {
          filteredReceipts = filterReceiptsBySellerNames(filteredReceipts, this.selectedEmployees);
        }
        if (filteredReceipts.length > 0) {
          data.push({ label: itemName, value: Number(getTotalByItem(filteredReceipts, itemName)) });
        }
      });

      data.sort((item1, item2) => {
        return item1.value < item2.value ? 1 : -1;
      });

      // If 7 or more items filter 6 most sold items, and rest is accumulated as 7th point
      const threshold = 7;
      if (data.length > threshold) {
        const others = data.slice(threshold - 1).reduce((sum, item) => {
          return sum + item.value;
        }, 0);
        data.splice(threshold - 1);
        data.push({ label: this.$t('dashboard.other'), value: others });
      }

      return {
        data: data.map((item) => item.value),
        labels: data.map((item) => item.label),
      };
    },
    profitInTheDay() {
      const data = [];
      const labels = [];

      const filteredRecipts = filterReceiptsByTimestamp(
        this.receiptsByLocation,
        'after',
        dateToSeconds(this.profitInDay.selectedTimespan),
      );

      const today = new Date();
      const difference = today.getTime() - this.profitInDay.selectedTimespan.getTime();
      const numberOfDays = Math.ceil(difference / (1000 * 3600 * 24));

      const receiptsByHour = groupReceiptsByIssuedHour(filteredRecipts);
      if (receiptsByHour.size > 0) {
        const firstHour = Array.from(receiptsByHour.keys())[0] || 0;
        const lastHour = Array.from(receiptsByHour.keys()).pop() || 0;
        const timespanToShow = [...Array(lastHour - firstHour + 1).keys()].map(
          (x) => x + firstHour,
        );

        timespanToShow.forEach((currentHour) => {
          labels.push(currentHour + ' h');
          if (receiptsByHour.has(currentHour)) {
            const receiptsInHour = receiptsByHour.get(currentHour);
            data.push(getTotal(receiptsInHour) / numberOfDays);
          } else {
            data.push(0);
          }
        });
      }

      return {
        data,
        labels,
      };
    },
    employees() {
      return this.loaded ? Array.from(this.revenueBySellerMap.keys()) : [];
    },
  },
  mounted() {
    this.registerReceiptsListener();
  },
  unmounted() {
    if (this.receiptsListenerUnsubscribe) {
      this.receiptsListenerUnsubscribe();
    }
  },
  mixins: [companyChangeWatcher],
  methods: {
    onCompanyChangeUpdate() {
      this.registerReceiptsListener();
    },
    registerReceiptsListener() {
      this.showLoader();

      if (this.receiptsListenerUnsubscribe) {
        this.receiptsListenerUnsubscribe();
      }

      const companyId = store.getCurrentCompanyId();

      const week = startOfWeek();
      const month = startOfMonth();
      const dateFrom = week < month ? week : month;

      const query = df
        .collection(`receipts/${companyId}/receipts`)
        .where('issue_date_time', '>=', dateToSeconds(dateFrom))
        .orderBy('issue_date_time', 'asc');

      this.allReceipts = [];
      this.receiptsListenerUnsubscribe = query.onSnapshot((doc) => {
        doc.docChanges().forEach((change) => {
          if (change.type === 'added') {
            this.allReceipts.push(change.doc.data());
          }
          if (change.type === 'modified') {
            const modifiedData = change.doc.data();
            const indexToReplace = this.allReceipts.findIndex(
              (item) => item.id === modifiedData.id,
            );
            this.allReceipts.splice(indexToReplace, 1, modifiedData);
          }
          if (change.type === 'removed') {
            const modifiedData = change.doc.data();
            const indexToReplace = this.allReceipts.findIndex(
              (item) => item.id === modifiedData.id,
            );
            this.allReceipts.splice(indexToReplace, 1);
          }
        });

        this.loaded = true;
        this.hideLoader();
      });
    },
  },
};
</script>

<style scoped>
.profit-card {
  position: relative;
  height: 90px;
  width: 250px;
  overflow: hidden;
}

.profit-card::before {
  position: absolute;
  content: '';
  display: inline-block;
  width: 120px;
  height: 120px;
  top: 55px;
  left: 85px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}

.profit-card::after {
  position: absolute;
  content: '';
  display: inline-block;
  width: 145px;
  height: 145px;
  top: -55px;
  right: -20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}

.revenue {
  background-image: linear-gradient(
    to right,
    rgba(var(--v-theme-info-lighten-1), 0.9),
    rgba(var(--v-theme-success-lighten-4), 0.75)
  );
}
.tax {
  background-image: linear-gradient(
    to right,
    rgba(var(--v-theme-error-lighten-2), 0.9) 60%,
    rgba(var(--v-theme-warning-lighten-4), 0.75)
  );
}

.difference {
  background-image: linear-gradient(
    to right,
    rgba(var(--v-theme-warning-lighten-1), 0.9) 60%,
    rgba(var(--v-theme-warning-lighten-4), 0.75)
  );
}
</style>
