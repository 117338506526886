import { df } from '../plugins/firebase';
import store from '../store';
import { dateToSeconds, endOfDay, startOfDay } from '../utils/util';

const getUser = async function () {
  const user = await df.doc(`users/${store.getUserId()}`).get();
  return user.data();
};

const getCompanies = async function () {
  const query = await df.doc(`user_companies/${store.getUserId()}`).get();
  if (query.data() && query.data().companies) {
    const companies = Object.values(query.data().companies);
    return companies;
  } else {
    return [];
  }
};

const getLocations = async function (companyId) {
  const query = await df.doc(`user_locations/${store.getUserId()}.${companyId}`).get();
  const data = query.data();
  if (data && data.locations) {
    const locations = Object.values(data.locations);
    return locations;
  } else {
    return [];
  }
};

const getReceipts = async function (dateFrom, dateTo) {
  const companyId = store.getCurrentCompanyId();

  let query = df
    .collection(`receipts/${companyId}/receipts`)
    .where('issue_date_time', '>=', dateToSeconds(dateFrom))
    .where('issue_date_time', '<=', dateToSeconds(dateTo))
    .orderBy('issue_date_time', 'asc');

  let allReceipts = [];
  const documentSnapshots = await query.get();
  if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
    documentSnapshots.docs.forEach((item) => {
      allReceipts.push(item.data());
    });
  }

  allReceipts = allReceipts.filter((receipt) => {
    return receipt.payment.current_status === 'PAID' && receipt.type.flag === 'BO';
  });

  return allReceipts;
};

const getSuppliers = async function () {
  const companyId = store.getCurrentCompanyId();

  const query = df.collection('suppliers').where('company_ids', 'array-contains', companyId);

  const documentSnapshots = await query.get();
  return documentSnapshots.docs
    .map((item) => item.data())
    .sort((a, b) => (a.name < b.name ? -1 : 1));
};

const getWarehouses = async function (companyId) {
  let query = df
    .collection('warehouses/')
    .where('company_id', '==', companyId)
    .where('selling_warehouse', '==', true)
    .where('status', '==', 'OK');

  let warehouses = [];
  const documentSnapshots = await query.get();
  if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
    documentSnapshots.docs.forEach((item) => {
      warehouses.push(item.data());
    });
  }

  return warehouses
    .map((warehouse) => {
      return {
        ...warehouse,
        name: warehouse.name.replace(' - prodajno skladište', ' '),
      };
    })
    .sort((warehouse1, warehouse2) => (warehouse1.name < warehouse2.name ? -1 : 1));
};

const getWarehouseDeliveryNotes = async function (warehouseId, dateFrom, dateTo) {
  let query = df
    .collection(`warehouses/${warehouseId}/delivery_notes/`)
    .where('created', '>=', dateToSeconds(dateFrom))
    .where('created', '<=', dateToSeconds(dateTo));

  let deliveryNotes = [];
  const documentSnapshots = await query.get();
  if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
    documentSnapshots.docs.forEach((item) => {
      deliveryNotes.push(item.data());
    });
  }
  return deliveryNotes;
};

const getCashRegisters = async function (companyId) {
  const query = df
    .collection('cash_registers')
    .where('status', '==', 'OK')
    .where('company_id', '==', companyId);

  let cashRegisters = [];
  const documentSnapshots = await query.get();
  if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
    documentSnapshots.docs.forEach((item) => {
      cashRegisters.push(item.data());
    });
  }
  return cashRegisters
    .map((cashRegister) => {
      const location = store.companyLocations.find(
        (location) => location.id === cashRegister.location_id,
      );
      return {
        id: cashRegister.id,
        name: cashRegister.name,
        location: location?.name,
        locationId: cashRegister.location_id,
        nameAndLocation: `${cashRegister.name} (${location?.name}) `,
      };
    })
    .sort((a, b) => (a.name < b.name ? -1 : 1));
};

const getBalancesForCashRegister = async function (cashRegisterId, date) {
  const dateTo = dateToSeconds(endOfDay(date));
  const dateFrom = dateToSeconds(startOfDay(date));
  let query = df
    .collection(`cash_registers/${cashRegisterId}/balances`)
    .where('created', '>=', dateFrom)
    .where('created', '<', dateTo)
    .orderBy('created', 'desc')
    .limit(10);

  let balances = [];
  const documentSnapshots = await query.get();
  if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
    documentSnapshots.docs.forEach((item) => {
      balances.push(item.data());
    });
  }
  return balances;
};

export {
  getUser,
  getCompanies,
  getLocations,
  getReceipts,
  getSuppliers,
  getWarehouses,
  getWarehouseDeliveryNotes,
  getCashRegisters,
  getBalancesForCashRegister,
};
