<template>
  <BasePage :title="$t('financeReports')">
    <template #actions>
      <DatePicker monthPicker :initialDate="selectedDate" @onDateChange="selectMonth" />
    </template>
    <template #content>
      <div class="mb-8">
        <v-card class="px-4 pt-6 text-grey-darken-1">
          <div class="text-capitalize font-weight-bold text-h5 mb-2">{{ currentPeriod }}</div>
          <FinancialReportChart :providedData="barChartData" />
          <div class="my-4 text-grey-darken-1">
            <div class="d-flex ml-2">
              <span class="w-25 ml-2 outgoing">{{ $t('reports.outgoing') }}</span>
              <span><AnimatedNumber :value="outgoing.total" /></span>
            </div>
            <div class="d-flex ml-2">
              <span class="w-25 ml-2 incoming">{{ $t('reports.incoming') }}</span>
              <span><AnimatedNumber :value="incoming.total" /></span>
            </div>
          </div>
        </v-card>
      </div>

      <v-card>
        <v-card-text>
          <v-table>
            <thead>
              <tr>
                <th></th>
                <th class="text-left">{{ $t('reports.base') }}</th>
                <th class="text-left">{{ $t('reports.tax') }}</th>
              </tr>
            </thead>
            <tbody class="text-caption">
              <tr>
                <td class="text-grey text-uppercase">{{ $t('reports.incoming') }}</td>
                <td><AnimatedNumber :value="incoming.base" /></td>
                <td><AnimatedNumber :value="incoming.tax" /></td>
              </tr>
              <tr>
                <td class="text-grey text-uppercase">{{ $t('reports.outgoing') }}</td>
                <td><AnimatedNumber :value="outgoing.base" /></td>
                <td><AnimatedNumber :value="outgoing.tax" /></td>
              </tr>
              <tr>
                <td class="text-grey text-uppercase">{{ $t('reports.difference') }}</td>
                <td><AnimatedNumber :value="difference.base" /></td>
                <td><AnimatedNumber :value="difference.tax" /></td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </template>
  </BasePage>
</template>

<script>
import AnimatedNumber from '../components/AnimatedNumber.vue';
import BasePage from '../components/BasePage.vue';
import DatePicker from '../components/DatePicker.vue';
import { getReceipts, getWarehouseDeliveryNotes } from '../queries';
import store from '../store';
import { getTotal, getTotalTax, filterReceiptsByLocationId } from '../utils/receipts';
import { getMonthAndYear, getFirstDayOfMonth } from '../utils/util';
import { calculateIncoming } from '../utils/deliveryNotes';
import FinancialReportChart from '../modules/financialReports/FinancialReportChart.vue';
import companyChangeWatcher from '../mixins/companyChangeWatcher';
import locationChangeWatcher from '../mixins/locationChangeWatcher';

export default {
  name: 'ReportsView',
  components: { AnimatedNumber, BasePage, DatePicker, FinancialReportChart },
  data() {
    return {
      selectedDate: { month: new Date().getMonth(), year: new Date().getFullYear() },
      allReceipts: [],
      deliveryNotes: [],
    };
  },
  inject: ['hideLoader', 'showLoader'],
  computed: {
    selectedPeriod() {
      const firstDayOfMonth = getFirstDayOfMonth(this.selectedDate.year, this.selectedDate.month);
      const firstDayNextMonth = getFirstDayOfMonth(
        this.selectedDate.year,
        this.selectedDate.month + 1,
      );
      return [firstDayOfMonth, firstDayNextMonth];
    },

    currentPeriod() {
      return getMonthAndYear(this.selectedDate.month, this.selectedDate.year, {
        month: 'long',
      });
    },
    difference() {
      return {
        base: (this.outgoing.base - this.incoming.base).toFixed(2),
        tax: (this.outgoing.tax - this.incoming.tax).toFixed(2),
      };
    },
    outgoing() {
      const locationId = store.getCurrentLocationId();

      let locationReceipts = this.allReceipts;
      if (locationId !== 'all_locations') {
        const location = store.companyLocations.find((loc) => loc.id === locationId);
        locationReceipts = filterReceiptsByLocationId(this.allReceipts, location.location_id);
      }

      const total = getTotal(locationReceipts);
      const tax = getTotalTax(locationReceipts);

      return {
        total,
        tax,
        base: (total - tax).toFixed(2),
      };
    },
    incoming() {
      return calculateIncoming(this.deliveryNotes);
    },
    locationWarehouses() {
      return store.getLocationWarehoues();
    },
    barChartData() {
      return {
        data1: [this.outgoing.total],
        data2: [this.incoming.total],
        labels: [
          getMonthAndYear(this.selectedDate.month, this.selectedDate.year, {
            year: '2-digit',
            month: 'short',
          }),
        ],
      };
    },
  },
  mounted() {
    this.loadReceipts();
    this.loadDeliveryNotes();
  },
  mixins: [companyChangeWatcher, locationChangeWatcher],
  methods: {
    onCompanyChangeUpdate() {
      this.loadReceipts();
      this.loadDeliveryNotes();
    },
    onLocationChangeUpdate() {
      this.loadDeliveryNotes();
    },
    selectMonth(payload) {
      this.selectedDate = payload;
      this.loadReceipts();
      this.loadDeliveryNotes();
    },
    async loadReceipts() {
      this.showLoader();

      const [dateFrom, dateTo] = this.selectedPeriod;

      this.allReceipts = await getReceipts(dateFrom, dateTo);

      this.hideLoader();
    },
    async loadDeliveryNotes() {
      this.showLoader();

      const [dateFrom, dateTo] = this.selectedPeriod;

      const deliveryNotesPromises = this.locationWarehouses.map((warehouse) => {
        return getWarehouseDeliveryNotes(warehouse.id, dateFrom, dateTo);
      });
      Promise.all(deliveryNotesPromises).then((deliveryNotes) => {
        this.deliveryNotes = deliveryNotes.reduce((arr, elem) => arr.concat(elem), []);
        this.loaded = true;
        this.hideLoader();
      });
    },
  },
};
</script>

<style>
.incoming {
  position: relative;
}
.incoming::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 1.3rem;
  background-color: rgb(var(--v-theme-incoming), 0.5);
  left: -15px;
}

.outgoing {
  position: relative;
}
.outgoing::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 1.3rem;
  background-color: rgb(var(--v-theme-outgoing), 0.5);
  left: -15px;
}
</style>
