<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="item in renderWarehouseItems" :key="item.id">
      <v-expansion-panel-title>
        <v-row>
          <v-col cols="7" md="5" lg="4">
            <div class="caption text-grey mb-1">{{ $t('warehouse.itemName') }}</div>
            <div>{{ item.shortName }}</div>
          </v-col>
          <v-col cols="3" lg="2">
            <div class="caption text-grey mb-1">{{ $t('warehouse.inStock') }}</div>
            <div>{{ item.stock }}</div>
          </v-col>
          <v-col cols="2" :class="{ 'd-none': isMobile }">
            <div class="caption text-grey mb-1">{{ $t('warehouse.code') }}</div>
            <div>{{ item.code }}</div>
          </v-col>
          <v-col cols="2" :class="{ 'd-none': isMobile }">
            <div class="caption text-grey mb-1">{{ $t('warehouse.description') }}</div>
            <div>{{ item.description }}</div>
          </v-col>
        </v-row>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row>
          <v-col>
            <div class="caption text-grey mb-1">{{ $t('warehouse.itemName') }}</div>
            <div>{{ item.name }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="caption text-grey mb-1">{{ $t('warehouse.description') }}</div>
            <div>{{ item.description }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <div class="caption text-grey mb-1">{{ $t('warehouse.inStock') }}</div>
            <div>{{ item.stock }}</div>
          </v-col>
          <v-col cols="4">
            <div class="caption text-grey mb-1">{{ $t('warehouse.code') }}</div>
            <div>{{ item.code }}</div>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <InfiniteLoading @infinite="load">
    <template #complete><span></span></template>
    <template #spinner>
      <div class="text-center mt-2">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
    </template>
  </InfiniteLoading>
</template>

<script>
import InfiniteLoading from 'v3-infinite-loading';
import 'v3-infinite-loading/lib/style.css';
import { df } from '../../plugins/firebase';
import { truncateString } from '../../utils/util';

export default {
  components: { InfiniteLoading },
  props: ['warehouseId', 'queryFilters'],
  data() {
    return {
      lastVisible: 0,
      warehouseItems: [],
      listeners: [],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown;
    },
    renderWarehouseItems() {
      return this.warehouseItems.map((warehouseItem) => {
        const stringLenght = this.$vuetify.display.mdAndUp ? 25 : 15;
        return {
          id: warehouseItem.id,
          name: warehouseItem.item.name,
          shortName: truncateString(warehouseItem.item.name, stringLenght),
          description: warehouseItem.description,
          code: warehouseItem.item.code,
          stock: warehouseItem.item.stock / 1000,
        };
      });
    },
  },
  beforeUnmount() {
    this.detachListeners();
  },
  methods: {
    detachListeners() {
      this.listeners.forEach((listener) => {
        listener();
      });
      this.listeners = [];
    },
    load($state) {
      const limit = 10;
      let loadAllAtOnce = false;
      try {
        let query = df.collection(`warehouses/${this.warehouseId}/extended_items/`);

        if (this.queryFilters.items && this.queryFilters.items.length > 0) {
          loadAllAtOnce = true;
          query = query.where('item.name', 'in', this.queryFilters.items);
        }

        if (this.queryFilters.supplier) {
          query = query.where('item.supplier_ids', 'array-contains', this.queryFilters.supplier.id);
        }

        if (!loadAllAtOnce) {
          query = query.limit(limit).orderBy('item.name').startAfter(this.lastVisible);
        }

        const listener = query.onSnapshot((doc) => {
          doc.docChanges().forEach((change) => {
            if (change.type === 'added') {
              this.warehouseItems.push(change.doc.data());
            }
            if (change.type === 'modified') {
              const modifiedData = change.doc.data();
              const indexToReplace = this.warehouseItems.findIndex(
                (item) => item.id === modifiedData.id,
              );
              this.warehouseItems.splice(indexToReplace, 1, modifiedData);
            }
            if (change.type === 'removed') {
              const modifiedData = change.doc.data();
              const indexToReplace = this.warehouseItems.findIndex(
                (item) => item.id === modifiedData.id,
              );
              this.warehouseItems.splice(indexToReplace, 1);
            }
          });
          if (loadAllAtOnce) {
            $state.complete();
          } else if (doc && !doc.empty) {
            this.lastVisible = doc.docs[doc.docs.length - 1].data().item.name;
            if (doc.docs.length < limit) {
              $state.complete();
            } else {
              $state.loaded();
            }
          } else {
            $state.complete();
          }
        });
        this.listeners.push(listener);
      } catch (error) {
        console.log(error);
        $state.error();
      }
    },
  },
};
</script>
