const errors = {
  'auth/user-not-found': 'errors.noCorrespondingUser',
  'auth/wrong-password': 'errors.wrongPassword',
  'auth/too-many-requests': 'errors.tooManyRequests',
};

function getMessageByKey(key) {
  return errors[key] ?? '';
}

export { getMessageByKey };
