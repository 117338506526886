import store from '../store';

export default {
  created() {
    this.$watch(
      () => store.getCurrentCompanyId(),
      () => {
        this.onCompanyChangeUpdate();
      },
    );
  },
};
