<template>
  <v-container class="fill-height">
    <v-row class="justify-center">
      <v-col sm="8" md="6" lg="4" xl="3">
        <v-card class="pa-5" :loading="submitting">
          <v-card-text>
            <v-form @submit.prevent="login" v-model="form">
              <v-text-field
                label="Email"
                variant="outlined"
                rounded
                v-model="email"
                prepend-inner-icon="mdi-email"
                autofocus
                :rules="rules.emailRules"
                :readonly="submitting"
              ></v-text-field>
              <v-text-field
                :label="$t('password')"
                variant="outlined"
                rounded
                autocomplete
                v-model="password"
                prepend-inner-icon="mdi-lock"
                :type="passVisible ? 'text' : 'password'"
                :append-inner-icon="passVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="rules.passwordRules"
                :readonly="submitting"
                @click:append-inner="() => (passVisible = !passVisible)"
              ></v-text-field>
              <v-btn
                block
                x-large
                type="submit"
                color="primary"
                :loading="submitting"
                :disabled="!form"
              >
                {{ $t('login') }}
              </v-btn>
            </v-form>
            <div v-if="error.show" class="text-error mt-3">
              {{ error.message }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { auth } from '../plugins/firebase';
import { startSession, checkSessionStatus } from '../plugins/auth';
import rules from '../utils/validation';
import store from '../store';
import { getMessageByKey } from '../utils/errorCodes';
import { getCompanies } from '../queries';

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      form: false,
      passVisible: false,
      submitting: false,
      rules: {
        emailRules: [
          rules.required(this.$t('messages.requiredField')),
          rules.email(this.$t('messages.invalidEmail')),
        ],
        passwordRules: [rules.required(this.$t('messages.requiredField'))],
      },
      error: {
        show: false,
        message: '',
      },
    };
  },
  methods: {
    login() {
      this.submitting = true;
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async (authResp) => {
          if (authResp.user) {
            if (authResp.user.emailVerified) {
              const sessionStatus = await checkSessionStatus();
              if (sessionStatus) {
                //TODO: Handle existing session
              } else {
                await startSession({
                  email: this.email,
                  password: this.password,
                });
              }
              this.submitting = false;
              store.setUserId(authResp.user.uid);

              const companies = await getCompanies();
              if (companies.length > 0) {
                const currentCompanyId = store.getCurrentCompanyId();
                if (
                  !currentCompanyId ||
                  !companies.some((company) => company.id === currentCompanyId)
                ) {
                  store.setCurrentCompanyId(companies[0].id);
                }
              }
              this.$router.push({
                name: 'dashboard',
              });
            } else {
              this.error.show = true;
              this.error.message = this.$t('messages.unconfirmedEmail');
              this.submitting = false;
            }
          }
        })
        .catch((err) => {
          this.error.show = true;
          this.error.message = this.$t(getMessageByKey(err.code)) || err;
          this.submitting = false;
        });
    },
  },
};
</script>
