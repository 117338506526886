import { auth, functions, firebase } from './firebase';

const reauthenticateUser = async function ({ email, password }) {
  const credential = firebase.auth.EmailAuthProvider.credential(email, password);
  try {
    return await auth.currentUser.reauthenticateWithCredential(credential);
  } catch (error) {
    console.log('Reauthenticate failed:', error);
    return null;
  }
};

const startSession = async function (credential) {
  const StartNewSession = functions.httpsCallable('StartNewSession');
  const startSessionResp = await StartNewSession({});
  if (startSessionResp?.data?.header?.code !== 200) {
    //TODO: Handle error
    console.log('Error in StartNewSession');
    return null;
  }
  return reauthenticateUser(credential);
};

const checkSessionStatus = async function () {
  const CheckSessionStatus = functions.httpsCallable('CheckSessionStatus');
  const checkSessionResp = await CheckSessionStatus({});
  if (checkSessionResp?.data?.header?.code !== 200) {
    return Promise.reject(new Error('Error in CheckSessionStatus'));
  }
  if (checkSessionResp.data.session) {
    //TODO: Handle existing session
    return true;
  }
  return false;
};

const endSession = function () {
  const EndSession = functions.httpsCallable('EndSession');
  EndSession({})
    .finally(async () => {
      await auth.signOut();
    })
    .catch((error) => {
      //TODO: Handle error
      console.log(error);
    });
};

export { startSession, endSession, checkSessionStatus };
